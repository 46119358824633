import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
export const AlertContext = createContext({});
export const AlertContextProvider = ({ children }) => {
    const [showVideoAlert, setShowVideoAlert] = useState();
    const [showNewEmailAlert, setShowNewEmailAlert] = useState();
    const [tries, setTries] = useState(0);
    const context = useMemo(() => ({ showVideoAlert, setShowVideoAlert, tries, setTries, showNewEmailAlert, setShowNewEmailAlert }), [showVideoAlert, setShowVideoAlert, showNewEmailAlert, setShowNewEmailAlert]);
    return _jsx(AlertContext.Provider, Object.assign({ value: context }, { children: children }));
};
export const useAlertContext = () => useContext(AlertContext);
