import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeRoutes, AuthorizedStudentRoutes, AuthorizedAdminRoutes, AuthorizedSchoolLeaderRoutes, AuthorizedBuddiesRoutes, AuthorizedSchoolTeacherRoutes, } from './routes/Routes';
import Loader from './layouts/Loader/Loader';
import { useAuth } from './hooks/useAuth';
import { ROLES_ENUM } from './constants/Roles';
import { useAxiosInterceptors } from './services/API';
const App = () => {
    const { isLoggedIn, role } = useAuth();
    useAxiosInterceptors();
    const whichRouting = () => {
        if (!isLoggedIn)
            return ThemeRoutes;
        switch (role) {
            case ROLES_ENUM.STUDENT:
                return AuthorizedStudentRoutes;
            case ROLES_ENUM.BUDDY:
                return AuthorizedBuddiesRoutes;
            case ROLES_ENUM.ADMIN:
                return AuthorizedAdminRoutes;
            case ROLES_ENUM.LEADER:
                return AuthorizedSchoolLeaderRoutes;
            case ROLES_ENUM.TEACHER:
                return AuthorizedSchoolTeacherRoutes;
            default:
                return ThemeRoutes;
        }
    };
    return (_jsx(Suspense, Object.assign({ fallback: _jsx(Loader, {}) }, { children: _jsx(_Fragment, { children: useRoutes(whichRouting()) }) })));
};
export default App;
