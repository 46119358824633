var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import Swal from 'sweetalert2';
import { useAuth } from '../hooks/useAuth';
const apiURL = process.env.API_URL;
export const reduxENV = process.env.REDUX_ENV;
export const API = axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    },
});
export const useAxiosInterceptors = () => {
    const { doLogout } = useAuth();
    API.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    API.interceptors.response.use((response) => {
        return response;
    }, (error) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        const originalRequest = error.config;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 &&
            (((_b = error.response) === null || _b === void 0 ? void 0 : _b.data.message) === 'JWT is expired' ||
                ((_c = error.response) === null || _c === void 0 ? void 0 : _c.data.message.includes('token is expired by'))) &&
            !originalRequest._retry) {
            originalRequest._retry = true;
            Swal.fire({
                icon: 'error',
                title: 'Session has expired',
                text: 'Token invalid now',
            });
            yield doLogout();
        }
        return Promise.reject(error);
    }));
};
export const APINoAuthNeeded = axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    },
});
