import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SidebarContextProvider } from './contexApi/SidebarContext';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import 'react-player';
import { PersistGate } from 'redux-persist/integration/react';
import './assets/scss/style.scss';
import { AlertContextProvider } from './contexApi/AlertContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(Provider, Object.assign({ store: store }, { children: _jsx(PersistGate, Object.assign({ persistor: persistor }, { children: _jsx(BrowserRouter, { children: _jsx(SidebarContextProvider, { children: _jsx(AlertContextProvider, { children: _jsx(App, {}) }) }) }) })) })));
